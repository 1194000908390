@import "mixins";
@import "variables";
@import "animations";
@import "../components/navbar/navbar";
@import "../components/about/about";
@import "../components/experience/experience";
@import "../components/projects/projects";
@import "../components/footer/footer";

@font-face {
  font-family: "Cairo_Light";
  src: url(./fonts/Cairo-Light.ttf);
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Cairo_Regular";
  src: url(./fonts/Cairo-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Cairo_Medium";
  src: url(./fonts/Cairo-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Cairo_SemiBold";
  src: url(./fonts/Cairo-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Cairo_Bold";
  src: url(./fonts/Cairo-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Cairo_ExtraBold";
  src: url(./fonts/Cairo-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Cairo_Black";
  src: url(./fonts/Cairo-Black.ttf);
  font-weight: 900;
  font-style: normal;
}

* {
  font-family: $fontFamilyRegular;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  z-index: 1;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $color-second;
  div#body {
    min-height: 100vh;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 280px) {
  .container,
  .container-sm {
    width: 98%;
    max-width: 250px;
    min-width: 250px;
  }
}
@media (min-width: 281px) and (max-width: 576px) {
  .container,
  .container-sm {
    max-width: 576px;
    width: 90%;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 550px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 700px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 800px;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 800px;
  }
}

.light {
  .text {
    color: $color-dark;
  }

  .background {
    background-color: $color-white;
  }
}

.dark {
  .text {
    color: $color-white;
  }

  .background {
    background-color: $color-dark;
  }
}
