.slide-in {
  -webkit-animation: slide-in 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(15rem);
    transform: translateX(15rem);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide-in {
  0% {
    -webkit-transform: translateX(15rem);
    transform: translateX(15rem);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
