$fontSize: 14px;
$fontFamilyLight: "Cairo_Light", serif;
$fontFamilyRegular: "Cairo_Regular", serif;
$fontFamilyMedium: "Cairo_Medium", serif;
$fontFamilySemiBold: "Cairo_SemiBold", serif;
$fontFamilyBold: "Cairo_Bold", serif;
$fontFamilyExtraBold: "Cairo_ExtraBold", serif;
$fontFamilyBlack: "Cairo_Black", serif;

$xs: 575.98px;
$sm: 767.98px;
$md: 991.98px;
$lg: 1199.98px;

$color-white: #efeeee;
$color-dark: #161616;
$color-dark-2: #1f1f1f;
$color-grey: #a3a3a3;
$color-primary: #ff7a5c;
$color-second: #ffa996;
$color-accent: #e8cec3;
