.experience {
  padding-top: 7rem;

  .section-title {
    font-family: $fontFamilyBold;
    font-size: $fontSize + 10;

    @media (max-width: $xs) {
      font-size: $fontSize + 7;
    }
  }

  .experience-item {
    margin-top: 1.5rem;
    white-space: pre-line;
  }

  .experience-date {
    @include _flex(row, start, start);
    color: $color-second;
    font-size: $fontSize - 1;

    @media (max-width: $xs) {
      font-size: $fontSize - 2;
    }
  }

  .experience-title {
    font-size: $fontSize + 2;
    font-family: $fontFamilyBold;

    @media (max-width: $xs) {
      font-size: $fontSize + 1;
    }
  }

  .experience-subtitle {
    @include _flex(row, start, center);
    font-size: $fontSize - 1;
    padding-bottom: 3px;
    padding-top: 3px;

    color: $color-grey;
    font-family: $fontFamilyLight;

    @media (max-width: $xs) {
      font-size: $fontSize - 2;
    }
  }

  .experience-company {
    @include _flex(row, center, center);
    font-size: $fontSize;
    font-family: $fontFamilyBold;

    @media (max-width: $xs) {
      font-size: $fontSize - 1;
    }
  }

  .experience-logo {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 0.15rem;
    margin-right: 0.5rem;
    background-size: cover;
    background-position: center;

    @media (max-width: $xs) {
      width: 1.1rem;
      height: 1.1rem;
    }
  }

  .experience-description {
    font-size: $fontSize + 1;
    font-family: $fontFamilyRegular;

    @media (max-width: $xs) {
      font-size: $fontSize;
    }
  }
  .experience-links {
    @include _flex(row, flex-start, center);
    flex-wrap: wrap;
  }

  .link-button {
    @include _flex(row, center, center);
    flex-grow: 0;
    cursor: pointer;
    padding: 0.2rem 1.2rem;
    font-family: $fontFamilySemiBold;
    text-decoration: none;
    font-size: $fontSize;
    border-radius: 10px;
    margin-top: 0.7rem;
    transition: all 0.2s ease-in-out;
    margin-right: 1rem;
    border: 1px solid $color-primary;

    @media (max-width: $xs) {
      padding: 0.2rem 1rem;
    }

    .link-icon {
      font-size: $fontSize + 3;
      margin-right: 0.4rem;

       @media (max-width: $xs) {
        font-size: $fontSize + 1;
        margin-right: 0.3rem;
      }
    }
  }

  .link-button:hover {
    background-color: $color-second;
  }

  .view-all-button {
    @include _flex(row, start, center);
    padding-top: 1rem;
    cursor: pointer;

    .view-all-button-text {
      text-decoration-line: underline;
      font-size: $fontSize + 2;
      font-family: $fontFamilySemiBold;
      padding-right: 0.3rem;

      @media (max-width: $xs) {
        font-size: $fontSize + 1;
      }
    }
    .view-all-button-icon {
      font-size: $fontSize + 5;

      @media (max-width: $xs) {
        font-size: $fontSize + 4;
      }
    }
  }
}

.light {
  .link-button {
    color: $color-dark;
  }
}

.dark {
  .link-button {
    color: $color-white;
  }
}
