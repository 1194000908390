.navbar {
  @include _flex(row, space-between, baseline);
  user-select: none;
  padding-top: 0.7rem;

  @media (max-width: $xs) {
    font-size: $fontSize + 6;
  }

  .logo {
    font-size: $fontSize + 8;
    font-family: $fontFamilyBlack;

    @media (max-width: $xs) {
      font-size: $fontSize + 5;
    }

    @media (min-width: $xs) and (max-width: $sm) {
      font-size: $fontSize + 7;
    }
  }

  .logo-dot {
    color: $color-primary;
    font-size: $fontSize + 14;
  }

  .light-icon {
    cursor: pointer;
    color: $color-white;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .dark-icon {
    cursor: pointer;
    color: $color-white;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}