.footer {
  padding-top: 7rem;
  padding-bottom: 3rem;

  .contact-title {
    font-size: $fontSize + 2;
    color: $color-grey;
    @media (max-width: $xs) {
      font-size: $fontSize - 1;
    }

    @media (min-width: $xs) and (max-width: $sm) {
      font-size: $fontSize + 1;
    }
  }

  .interested {
    font-size: $fontSize + 2;
    .question-mark {
      color: $color-primary;
    }

    @media (max-width: $xs) {
      font-size: $fontSize;
    }

    @media (min-width: $xs) and (max-width: $sm) {
      font-size: $fontSize + 1;
    }
  }
  .send-email {
    font-size: $fontSize + 1;
    padding-bottom: 3rem;

    .email {
      color: $color-primary;
    }

    @media (max-width: $xs) {
      font-size: $fontSize - 1;
    }

    @media (min-width: $xs) and (max-width: $sm) {
      font-size: $fontSize;
    }
  }

  .crafted-by-me {
    font-size: $fontSize + 2;
    .copyright {
      color: $color-primary;
    }

    @media (max-width: $xs) {
      font-size: $fontSize - 1;
    }

    @media (min-width: $xs) and (max-width: $sm) {
      font-size: $fontSize + 1;
    }
  }

  .social-row {
    @include _flex(row, space-between, flex-end);

    .socials {
      @include _flex(row, flex-start, start);

      .social-button {
        @include _flex(row, center, center);
        padding-right: 1.5rem;
        @media (max-width: $xs) {
          padding-right: 0.7rem;
        }

        .social-button-name {
          padding-right: 0.2rem;
          font-size: $fontSize + 2;
          @media (max-width: $xs) {
            font-size: $fontSize - 1;
          }

          @media (min-width: $xs) and (max-width: $sm) {
            font-size: $fontSize + 1;
          }
        }

        .social-icon {
          font-size: $fontSize + 3;
          @media (max-width: $xs) {
            font-size: $fontSize - 1;
          }

          @media (min-width: $xs) and (max-width: $sm) {
            font-size: $fontSize + 1;
          }
        }
      }
    }
  }
}
