@mixin _flex($direction, $content, $items) {
  display: flex;
  justify-content: $content;
  align-items: $items;
  flex-direction: $direction;
}

@mixin _selectionOff {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
}
