.about {
  padding-top: 7rem;

  p {
    font-size: $fontSize + 3;
    font-family: $fontFamilyMedium;

    @media (max-width: $xs) {
      font-size: $fontSize;
    }
  }

  .avatar-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-primary;
    border-radius: 50%;
    z-index: 0;
  }
}

.dark {
  .avatar-background {
    background-color: $color-white;
  }
}

.light {
  .avatar-background {
    background-color: $color-dark;
  }
}
